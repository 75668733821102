
*::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
    overflow-x: hidden;
}

#root {
    height: 100%;
}
